// assets
import { IconGoGame, IconBrandTrello, IconListDetails } from '@tabler/icons-react';

// constant
const icons = {
  IconGoGame,
  IconBrandTrello,
  IconListDetails
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'mind-map',
      title: 'Mind map',
      type: 'item',
      icon: icons.IconGoGame,
      url: '/mind-map',
      breadcrumbs: true
    },
    {
      id: 'task-board',
      title: 'Task board',
      type: 'item',
      icon: icons.IconBrandTrello,
      url: '/task-board/kanban',
      breadcrumbs: true
      // children: [
      //   {
      //     id: 'login3',
      //     title: 'Login',
      //     type: 'item',
      //     url: '/pages/login/login3',
      //     target: true
      //   },
      //   {
      //     id: 'register3',
      //     title: 'Register',
      //     type: 'item',
      //     url: '/pages/register/register3',
      //     target: true
      //   }
      // ]
    },
    {
      id: 'to-do',
      title: 'To-do',
      type: 'item',
      icon: icons.IconListDetails,
      url: '/todo',
      breadcrumbs: true
    }
  ]
};

export default pages;
