import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import { EdgesMindMap, NodesMindMap } from 'types';
import { KanbanCard, kanbanCounts } from 'types/app';

type MindMapTyle = {
  nodes: NodesMindMap[];
  edges: EdgesMindMap[];
};

export interface State {
  isLoading: boolean;
  totalEventThisMonth: number;
  totalTodo: number;
  kanaban: {
    kanbanCounts: kanbanCounts[];
    recentAdded: KanbanCard[];
  };
  mindmap: MindMapTyle;
}

const initialState: State = {
  isLoading: true,
  totalEventThisMonth: 0,
  totalTodo: 0,
  kanaban: {
    kanbanCounts: [],
    recentAdded: []
  },
  mindmap: {
    edges: [],
    nodes: []
  }
};

const slice = createSlice({
  initialState: initialState,
  name: 'dashboard',
  reducers: {
    setDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalEventThisMonth: (state, action: PayloadAction<number>) => {
      state.totalEventThisMonth = action.payload;
    },
    setTodoCount: (state, action: PayloadAction<number>) => {
      state.totalTodo = action.payload;
    },
    setKanbanCounts: (state, action: PayloadAction<kanbanCounts[]>) => {
      state.kanaban.kanbanCounts = action.payload;
    },
    setKanbanRecentAdd: (state, action: PayloadAction<KanbanCard[]>) => {
      state.kanaban.recentAdded = action.payload;
    },
    setMindMap: (state, action: PayloadAction<MindMapTyle>) => {
      state.mindmap = action.payload;
    }
  }
});

export const { setDashboardLoading, setTotalEventThisMonth, setTodoCount, setKanbanCounts, setKanbanRecentAdd, setMindMap } = slice.actions;

export default slice.reducer;
