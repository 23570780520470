import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, SxProps } from '@mui/material';

interface MainCardProps {
  border?: boolean;
  hideDivider?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: SxProps;
  headerSX?: SxProps;
  darkTitle?: boolean;
  secondary?: React.ReactNode | string;
  shadow?: string;
  sx?: SxProps;
  title?: React.ReactNode | string;
}

const headerManualSX = {
  '& .MuiCardHeader-action': { mr: 0, mt: 0, mb: 0, ml: { xs: 0.5, sm: 0.5 } },
  alignItems: 'center'
};

const MainCard = forwardRef<HTMLDivElement, MainCardProps>((props, ref) => {
  const theme: any = useTheme();

  const {
    border = true,
    boxShadow,
    children,
    content = true,
    contentClass = '',
    contentSX = {},
    darkTitle,
    secondary,
    shadow,
    sx = {},
    title,
    headerSX,
    hideDivider,
    ...others
  } = props;

  return (
    <Card
      ref={ref}
      {...others}
      sx={{
        border: border ? '1px solid' : 'none',
        borderColor: theme.palette.primary[200] + 25,
        '&:hover': {
          boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
        },
        ...sx
      }}
    >
      {/* card header and action */}
      {title && (
        <CardHeader
          sx={{ ...headerManualSX, ...headerSX }}
          title={darkTitle ? <Typography variant="h3">{title}</Typography> : title}
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && !hideDivider && <Divider />}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
});

// MainCard.propTypes = {
//   border: PropTypes.bool,
//   boxShadow: PropTypes.bool,
//   children: PropTypes.node.isRequired,
//   content: PropTypes.bool,
//   contentClass: PropTypes.string,
//   contentSX: PropTypes.object,
//   darkTitle: PropTypes.bool,
//   secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
//   shadow: PropTypes.string,
//   sx: PropTypes.object,
//   title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
// };

export default MainCard;
