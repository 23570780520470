import dashboard from './dashboard';
import pages from './pages';
import settings from './settings';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, pages, settings]
};

export default menuItems;
