import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface State {
  todoLoading: boolean;
  progress: number;
}

const initialState: State = {
  todoLoading: true,
  progress: 100
};

const slice = createSlice({
  initialState: initialState,
  name: 'kanban',
  reducers: {
    setTodoLoading: (state, action: PayloadAction<boolean>) => {
      state.todoLoading = action.payload;
    },
    setTodoPercentage: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    }
  }
});

export const { setTodoLoading, setTodoPercentage } = slice.actions;

export default slice.reducer;
