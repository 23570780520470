// project imports

import React, { ReactNode } from 'react';

interface IAuthLayoutProps {
  children: ReactNode;
}

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout: React.FC<IAuthLayoutProps> = ({ children }) => <>{children}</>;

export default MinimalLayout;
