import { useLazyQuery } from '@apollo/client';
import { Backdrop, CircularProgress, IconButton, styled } from '@mui/material';
import { GET_ACCOUNT } from 'graphql/query/auth';
import React, { useEffect } from 'react';
import { accountInitialize, accountLoader } from 'store/features/auth.reducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';
import { IconSquareRoundedCheck, IconAlertSquareRounded, IconSquareRoundedX } from '@tabler/icons-react';
import IconMotion from 'ui-component/extended/IconMotion';
import CloseIcon from '@mui/icons-material/Close';

const StyledMaterialDesignContent = styled(MaterialDesignContent)((theme) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#edf7ed',
    color: '#1e4620',
    boxShadow: theme.theme.shadows[3],
    fontWeight: '500',
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 15
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#fdeded',
    color: '#5f2120',
    boxShadow: theme.theme.shadows[3],
    fontWeight: '500',
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 15
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#fff4e5',
    color: '#663c00',
    boxShadow: theme.theme.shadows[3],
    fontWeight: '500',
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 15
  }
}));

type ProviderProps = {
  children: React.ReactNode;
};

const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  const [getAccount, { data }] = useLazyQuery(GET_ACCOUNT);

  const dispatch = useAppDispatch();
  const { loadingAccount } = useAppSelector((s) => s.authReducer);

  useEffect(() => {
    if (data) {
      if (data?.account) {
        if (data?.account?.success) {
          if (data?.account?.account) {
            dispatch(accountInitialize(data?.account?.account));

            return;
          }
        } else {
          dispatch(accountLoader(false));
        }
        // enqueueSnackbar(data?.account?.message, { variant: 'error' });
      }
    }
  }, [data]);

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <>
      {loadingAccount ? (
        <LoadingSpin />
      ) : (
        <SnackbarProvider
          autoHideDuration={4000}
          preventDuplicate
          action={() => (
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()} sx={{ mr: 1 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          )}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent
          }}
          iconVariant={{
            success: (
              <IconMotion sx={{ mr: 1.5 }}>
                <IconSquareRoundedCheck />
              </IconMotion>
            ),
            error: (
              <IconMotion sx={{ mr: 1.5 }}>
                <IconSquareRoundedX />
              </IconMotion>
            ),
            warning: (
              <IconMotion sx={{ mr: 1.5 }}>
                <IconAlertSquareRounded />
              </IconMotion>
            )
          }}
        >
          {children}
        </SnackbarProvider>
      )}
    </>
  );
};

const LoadingSpin = () => {
  return (
    <Backdrop open={true} sx={{ color: '#fff' }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default AppProvider;
