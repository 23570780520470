import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CalenderEvent = Loadable(lazy(() => import('views/calender-event')));
const MindMap = Loadable(lazy(() => import('views/mind-mapping')));
const TaskApp = Loadable(lazy(() => import('views/task-management')));
const TodoApp = Loadable(lazy(() => import('views/todo')));
const ChangePassword = Loadable(lazy(() => import('views/user-settings/change-password')));
const UpdateProfile = Loadable(lazy(() => import('views/user-settings/update-profile')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

import { ROUTES } from './routes';
import { Navigate, Route, Routes } from 'react-router';

const MainRoutes = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path={ROUTES.INDEX} element={<DashboardDefault />} />
        <Route path={ROUTES.DASHBOARD.CALENDER} element={<CalenderEvent />} />
        <Route path={ROUTES.DASHBOARD.MIND_MAP} element={<MindMap />} />
        <Route path={ROUTES.DASHBOARD.TASK_BOARD} element={<TaskApp />} />
        <Route path={ROUTES.DASHBOARD.TODO} element={<TodoApp />} />
        <Route path={ROUTES.USER.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={ROUTES.USER.UPDATE_PROFILE} element={<UpdateProfile />} />
        <Route path={'*'} element={<Navigate to={ROUTES.INDEX} />} />
      </Routes>
    </MainLayout>
  );
};

export default MainRoutes;
