import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './features/auth.reducer';
import todo from './features/todo';
import dashboard from './features/dashboard';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  authReducer: authReducer,
  todo: todo,
  dashboard: dashboard
});

export default reducer;
