export const ROUTES = {
  INDEX: '/',
  AUTH: {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:token',
    VERIFY_EMAIL: '/verify/active?'
  },
  DASHBOARD: {
    CALENDER: '/calender-event',
    MIND_MAP: '/mind-map',
    TASK_BOARD: '/task-board/:view',
    TODO: '/todo'
  },
  USER: {
    CHANGE_PASSWORD: '/user/settings/change-password',
    UPDATE_PROFILE: '/user/settings/update-profile'
  }
};
