import React from 'react';
import { motion } from 'framer-motion';
import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const IconMotion = ({ children, sx }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
          delay: 0.2
        }}
        style={{ display: 'flex' }}
      >
        {children}
      </motion.div>
    </Box>
  );
};

export default IconMotion;
