// assets
import { IconSettings, IconLock } from '@tabler/icons-react';
import { ROUTES } from 'routes/routes';

// constant
const icons = { IconSettings, IconLock };

const settings_pages = {
  id: 'settings-pages',
  title: 'Settings',
  type: 'group',
  children: [
    {
      id: 'authentication',
      title: 'Account settings',
      type: 'collapse',
      icon: icons.IconSettings,
      children: [
        {
          id: 'change-pass',
          title: 'Change password',
          type: 'item',
          url: ROUTES.USER.CHANGE_PASSWORD,
          allow: ['EMAIL']
        },
        {
          id: 'update-profile',
          title: 'Profile',
          type: 'item',
          url: ROUTES.USER.UPDATE_PROFILE
        }
      ]
    }
  ]
};

export default settings_pages;
