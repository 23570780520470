import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/MinimalLayout';
import { Navigate, Route, Routes } from 'react-router-dom';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication3/Verify')));
const ForgotPass = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPass')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

import { ROUTES } from './routes';

const AuthRouting = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path={ROUTES.AUTH.LOGIN} element={<AuthLogin />} />
        <Route path={ROUTES.AUTH.REGISTER} element={<AuthRegister />} />
        <Route path={ROUTES.AUTH.VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPass />} />
        <Route path={ROUTES.AUTH.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path="*" element={<Navigate to={ROUTES.AUTH.LOGIN} />} />
      </Routes>
    </AuthLayout>
  );
};

export default AuthRouting;
