// routes
import MainRouting from './MainRoutes';
import AuthRouting from './AuthenticationRoutes';
import { useAppSelector } from 'store/hooks';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { account } = useAppSelector((state) => state.authReducer);

  return <>{!account ? <AuthRouting /> : <MainRouting />}</>;
}
