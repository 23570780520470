import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import { InMemoryCache, ApolloClient, ApolloProvider } from '@apollo/client';
import AppProvider from 'store/providers/AppProvider';

const client = new ApolloClient({
  uri: process.env.REACT_APP_NODE_ENV ? '/graphql' : process.env.REACT_APP_SERVER_URI + '/graphql',
  cache: new InMemoryCache(),
  credentials: 'include'
});

console.log(process.env.REACT_APP_NODE_ENV);

// ==============================|| APP ||============================== //
const App = () => {
  const customization = useSelector((state: any) => state.customization);

  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <AppProvider>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </AppProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};

export default App;
