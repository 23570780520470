// assets
import { IconDashboard, IconCalendarEvent } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconCalendarEvent };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'calender-event',
      title: 'Events',
      type: 'item',
      url: '/calender-event',
      icon: icons.IconCalendarEvent,
      breadcrumbs: true
    }
  ]
};

export default dashboard;
